import React from 'react';
import axios from 'axios';
import CommonNeighCard from '../../Classes/CommonNeighCard';
import GlobalField from '../../Classes/GlobalField';
import NoMatch from './NoMatch';
import { Redirect } from 'react-router-dom';
import { apiConfig } from '../../Classes/Helper.jsx';
//import FaqSection from '../../Classes/FaqSection';
import SectionTitle from '../../Classes/SectionTitle';
import UtilityNav from '../../Classes/UtilityNav';
import NhsDesktop from '../../Classes/NhsDesktop';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import callTrkSwap from '../../../helpers/callTrkSwap.js';
import PromoQMIList from '../../Classes/PromoQmiList.jsx';

class PromotionPage extends React.Component {
    constructor() {
        super();
        this.state = {
            promotion_data: [],
            success: false,
            message: '',
        };
    }

    componentDidMount() {
        axios.get(apiConfig().API_URL + '/promotion_details/' + this.props.match.params.slug).then((response) => {
            this.setState({
                promotion_data: response.data.data,
                success: response.data.success,
                message: response.data.message,
            });
        });

        callTrkSwap();
    }

    componentDidUpdate() {
        callTrkSwap();
        if (this.state.success == true) {
            window.dataLayer.push({
                event: 'communityId',
                divisionName: this.state.promotion_data.division_promotion
                    ? this.state.promotion_data.division_promotion
                    : this.state.promotion_data.page_title,
                division: this.state.promotion_data.division_promotion
                    ? this.state.promotion_data.division_promotion
                    : this.state.promotion_data.page_title,
                e1CommunityId: this.state.promotion_data.community_id ? this.state.promotion_data.community_id : '',
                salesforceId: this.state.promotion_data.salesforce_id ? this.state.promotion_data.salesforce_id : '',
            });
        }
        if (window.location.href.indexOf('#disclaimer') > -1 && document.getElementById('promo_disclaimer') != null) {
            document.querySelector('#promo_disclaimer').scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (window.location.href.indexOf('#interest-list') > -1) {
            document.getElementById('interest_click_btn').click();
        }
    }

    render() {
        var success = this.state.success;
        var message = this.state.message;
        var promotion_content = this.state.promotion_data;

        if (promotion_content.nhs_details) {
            var promo_form = promotion_content.nhs_details.promo_sidebar_form;
            var gc_count = promotion_content.global_components;
        }

        var pagetitle = promotion_content.page_title;
        var window_width = window.screen.width;

        if (!success) {
            if (message != 'Not Completed') {
                return (
                    <>
                        <Head promotion_content={promotion_content} />
                        <LoadingState />
                    </>
                );
            }
            return <Redirect to={<NoMatch />} />;
        }

        if (
            promotion_content.promo_data.community.length === 0 &&
            promotion_content.promo_data.neighborhood.length === 0 &&
            promotion_content.promo_data.qmi.length === 0 &&
            promotion_content.global_components === 0
        ) {
            return <Redirect to={<NoMatch />} />;
        }

        if (promotion_content.promo_data.promotion_status != 'Active') {
            return (
                <>
                    <Head promotion_content={promotion_content} />
                    <PromoInactive />
                </>
            );
        }

        return (
            <>
                <Head promotion_content={promotion_content} />
                <div role="main" className="cards-section">
                    {gc_count > 0 && (
                        <GlobalField
                            global_component={promotion_content.component}
                            slice_start={0}
                            slice_end={promotion_content.promo_data.cards_position}
                            fullImage="fullImage"
                        />
                    )}
                    {promotion_content.promo_data.enable_communities &&
                        promotion_content.promo_data.community.length > 0 && (
                            <>
                                <SectionTitle
                                    headline={promotion_content.canonical_pages_data.community_headline}
                                    subtext={
                                        promotion_content.canonical_pages_data.community_subtext +
                                        ' (' +
                                        promotion_content.promo_data.comm_count +
                                        ')'
                                    }
                                    posttype="promotion"
                                    promo_title_color={promotion_content.promo_data.promotion_accent_color}
                                />
                                <CommonNeighCard
                                    card_detail={promotion_content.promo_data.community}
                                    posttype="community"
                                    loadcard="false"
                                    page_type="promotion"
                                />
                            </>
                        )}
                    {promotion_content.promo_data.enable_neighborhoods &&
                        promotion_content.promo_data.neighborhood.length > 0 && (
                            <>
                                <SectionTitle
                                    headline={promotion_content.canonical_pages_data.neighborhood_headline}
                                    subtext={
                                        promotion_content.canonical_pages_data.neighborhood_subtext +
                                        ' (' +
                                        promotion_content.promo_data.neigh_count +
                                        ')'
                                    }
                                    posttype="promotion"
                                    promo_title_color={promotion_content.promo_data.promotion_accent_color}
                                />
                                <CommonNeighCard
                                    card_detail={promotion_content.promo_data.neighborhood}
                                    posttype="neighborhood"
                                    loadcard="false"
                                    page_type="promotion"
                                />
                            </>
                        )}
                    {promotion_content.promo_data.enable_qmis && promotion_content.promo_data.qmi.length > 0 && (
                        <>
                            <SectionTitle
                                headline={promotion_content.canonical_pages_data.qmi_headline}
                                subtext={
                                    promotion_content.canonical_pages_data.qmi_subtext +
                                    ' (' +
                                    promotion_content.promo_data.qmi_count +
                                    ')'
                                }
                                posttype="promotion"
                                promo_title_color={promotion_content.promo_data.promotion_accent_color}
                            />
                            <CommonNeighCard
                                card_detail={promotion_content.promo_data.qmi}
                                posttype="qmi"
                                loadcard="false"
                                sort_order={promotion_content.promo_data.Qmi_sort}
                                page_type="promotion"
                            />
                        </>
                    )}

                    {promotion_content.promo_data.enable_qmi_list && (
                        <PromoQMIList 
                            qmis={promotion_content.promo_data.qmi}
                            enabled={promotion_content.promo_data.enable_qmi_list}
                            listConfig={promotion_content.promo_data.qmi_list}
                            promoSnipe={promotion_content.promo_data.promo_snipe}
                            promoSnipeColor={promotion_content.promo_data.promo_snipe_color}
                            promoAccentColor={promotion_content.promo_data.promotion_accent_color}
                        />
                    )}
                    {gc_count >= promotion_content.promo_data.cards_position && (
                        <GlobalField
                            global_component={promotion_content.component}
                            slice_start={promotion_content.promo_data.cards_position}
                            promo_data={promotion_content.promo_data}
                            fullImage="fullImage"
                        />
                    )}
                    {promotion_content.promo_data.promotion_disclaimer && (
                        <div
                            className="promotion_disclaimer"
                            id="promo_disclaimer"
                            tabIndex="0"
                            dangerouslySetInnerHTML={{
                                __html: promotion_content.promo_data.promotion_disclaimer.replace(
                                    /(?:\r\n|\r|\n)/g,
                                    ''
                                ),
                            }}
                        />
                    )}
                    {window_width < 767 && (
                        <UtilityNav
                            promo_form={promo_form}
                            agent_info={promotion_content.nhs_details.agent_info}
                            sfdc_chat=""
                            promotion_nav="no"
                            remove_map="no"
                            community_component={promotion_content.nhs_details}
                            nbd_title={pagetitle}
                            posttype="promotion"
                            promo_title_color={promotion_content.promo_data.promotion_accent_color}
                            show_form={promotion_content.promo_data.show_form}
                        />
                    )}
                    {window_width > 767 && (
                        <NhsDesktop
                            promo_form={promo_form}
                            agent_info={promotion_content.nhs_details.agent_info}
                            sfdc_chat=""
                            community_component={promotion_content.nhs_details}
                            nbd_title={pagetitle}
                            posttype="promotion"
                            promo_title_color={promotion_content.promo_data.promotion_accent_color}
                            show_form={promotion_content.promo_data.show_form}
                        />
                    )}
                </div>
            </>
        );
    }
}

function PromoInactive() {
    return (
        <div className="promo_non_active">
            <SectionTitle headline="This promotion is no longer active" />
            <a className="non_active_btn" href="https://www.tripointehomes.com/">
                Go to Homepage
            </a>
        </div>
    );
}

function Head({ promotion_content }) {
    return (
        <Helmet>
            <title>{promotion_content.page_title}</title>
            <meta name="description" content={promotion_content.page_desc} />
            <meta property="og:title" content={promotion_content.page_title} />
            <meta property="og:description" content={promotion_content.page_desc} />
            <meta property="og:image" content={promotion_content.yoast_image} />
            <link rel="canonical" href={window.location.href} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="Website" />
        </Helmet>
    );
}

function LoadingState() {
    return (
        <SkeletonTheme>
            <div className="generic-main-div">
                <div className="generic-content divide-equal">
                    <div className="generic-content-section">
                        <div className="heading-section">
                            <Skeleton height={20} />
                            <Skeleton height={40} />
                        </div>
                        <Skeleton height={200} className="generic-description" />
                        <Skeleton height={20} width={100} />
                    </div>
                </div>
                <div className="generic-image divide-equal">
                    <Skeleton height={'100%'} />
                </div>
            </div>
            <div className="sectiontitle-main-div">
                <div className="sectiontitle-headline gray-color">
                    <Skeleton height={40} />
                </div>
                <div className="eyebrow-text-div">
                    <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text" />
                    <div className="eyebrowtext-border"></div>
                </div>
            </div>
            <div className="common-card">
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="sectiontitle-main-div">
                <div className="sectiontitle-headline gray-color">
                    <Skeleton height={40} />
                </div>
                <div className="eyebrow-text-div">
                    <Skeleton height={30} width={200} className="sectiontitle-eyebrow_text" />
                    <div className="eyebrowtext-border"></div>
                </div>
            </div>
            <div className="common-card">
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-main-div">
                    <div className="card-imgs">
                        <Skeleton height={'100%'} />
                        <div className="plan-img-info">
                            <div className="card-img-info">
                                <Skeleton height={20} className="card-status" />
                                <Skeleton height={40} className="card-name" />
                                <Skeleton height={20} className="card-price" />
                            </div>
                        </div>
                    </div>
                    <div className="card-info">
                        <ul className="card-detail">
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                            <li>
                                <Skeleton width={100} />
                                <Skeleton width={100} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
}

export default PromotionPage;
